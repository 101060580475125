import React, {useRef, useState} from 'react';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import "./App.css"


export default function PDF() {
    const [data,setData]=useState(null)
    function getData(val)
    {
        setData(val.target.value)

    }

    const pdfRef = useRef();

    const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {

        if (/iPhone|iPad/i.test(navigator.userAgent))
        {
            alert("oh sei un utente IOS, purtroppo non potrai scaricare il certificato a causa di diverse schermature del vostro dispositivo. Ma vi facciamo comunque i complimenti.");

        }
        else {

        alert("Potrebbe metterci qualche secondo!")
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape', 'px', 'a5', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 0;
        pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save("certificato.pdf");
        }

    });


    };


  return(
      <div >




          <div>
              <div className="card m-auto">

                  <div className="card-text bg text-center p-2"><b>Per scaricare il certificato scrivi nome e cognome</b></div>
                  <input type="text" onChange={getData} className="input" placeholder={"Nome e Cognome"}/>
                  <button onClick={downloadPDF} className="btn btn-lg btn-primary b"> Scarica certificato</button>
                  <a href="CertificatoIphone.png" className="bg" download="CertificatoIphone.png">Hai Iphone? Clicca qui</a>





                  </div>
              </div>






          <div ref={pdfRef} className={"cert"}>
              <div className={"nome"}>{data}</div>
          </div>

      </div>

  )
}

